<app-header 
    [titulo]="tituloDeLaVentana" 
    [subtitulo]="subtituloDeLaVentana"
    [botones]="listaDeBotones" 
    [showFilterPopup]="showFilterPopup"
    (onClose)="showFilterPopup = false" 
    (onSubmits)="onSubmit($event)"
    >
></app-header>
<app-spinner *ngIf="showSpinner"></app-spinner>
<!-- VISTA LISTA DE ESTUDIANTES -->
<app-grid [columns]="gridColumns" [data]="gridData" *ngIf="statusView=='grid'"></app-grid>