import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Router } from '@angular/router';
@Injectable({
  providedIn: 'root'
})
export class CrudService {

  constructor(private http: HttpClient, private router: Router) {}
  
  public createRecord(data: data): Observable<any[]> {
    //let url = 'https://wkvqd7vbe6.execute-api.us-east-1.amazonaws.com/QA/cl-university-console-backend/create_record'
    let url = 'https://wkvqd7vbe6.execute-api.us-east-1.amazonaws.com/PROD/cl-university-console-backend/create_record'

    return this.http.post(url, data, {
      headers: { 'content-type': 'application/json', 'authorization': localStorage.getItem('jwt')?.toString() ?? "" }
    }).pipe(
      map((response: any) => {
        if (response.statusCode === 401) {
          localStorage.removeItem('jwt');
          localStorage.removeItem('user');
          this.router.navigate(['/login']);
          window.location.reload();
        }
        return response;
      }),
      catchError((error) => {
        if (error.status === 401) {
        }
        // Propagar el error
        throw error;
      })
    );
  }
  public updateRecord(data: data): Observable<any[]> {
    //let url = 'https://wkvqd7vbe6.execute-api.us-east-1.amazonaws.com/QA/cl-university-console-backend/update_record'
    let url = 'https://wkvqd7vbe6.execute-api.us-east-1.amazonaws.com/PROD/cl-university-console-backend/update_record'

    return this.http.post(url, data, {
      headers: { 'content-type': 'application/json', 'authorization': localStorage.getItem('jwt')?.toString() ?? "" }
    }).pipe(
      map((response: any) => {
        if (response.statusCode === 401) {
          localStorage.removeItem('jwt');
          localStorage.removeItem('user');
          this.router.navigate(['/login']);
          window.location.reload();
        }
        return response;
      }),
      catchError((error) => {
        if (error.status === 401) {
        }
        // Propagar el error
        throw error;
      })
    );
  }  
  public deleteRecord(data: data): Observable<any[]> {
    //let url = 'https://wkvqd7vbe6.execute-api.us-east-1.amazonaws.com/QA/cl-university-console-backend/delete_record'
    let url = 'https://wkvqd7vbe6.execute-api.us-east-1.amazonaws.com/PROD/cl-university-console-backend/delete_record'
    console.log("data-dom-delete", data)
    return this.http.post(url, data, {
      headers: { 'content-type': 'application/json', 'authorization': localStorage.getItem('jwt')?.toString() ?? "" }
    }).pipe(
      map((response: any) => {
        if (response.statusCode === 401) {
          localStorage.removeItem('jwt');
          localStorage.removeItem('user');
          this.router.navigate(['/login']);
          window.location.reload();
        }
        return response;
      }),
      catchError((error) => {
        if (error.status === 401) {
        }
        // Propagar el error
        throw error;
      })
    );
  }    
}

export interface data {
  university_id: number;
  tablename: string;
  params: JSON;
}