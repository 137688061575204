import { Component, OnInit } from '@angular/core';
import { DataService } from '../data.service';
import { FormComponent } from '../form/form.component';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CrudService } from '../crud.service';
import { UtilService } from '../util.service';
import { SharedEventService } from '../shared-event.service';
import { MessageService } from '../message.service';


@Component({
  selector: 'app-student',
  templateUrl: './student.component.html',
  styleUrls: ['./student.component.css']
})
export class StudentComponent implements OnInit {
  university_id: number;
  gridColumns: any[] = [];
  gridData: any[] = [];
  selectedRows: any[] = [];
  groups: any[] = [];
  careers: any[] = [];
  showSpinner: boolean = false;
  form: FormGroup;
  statusView: string = 'grid';
  tituloDeLaVentana: string = 'Estudiantes';
  listaDeBotones: any[] = [
    { texto: 'Nuevo Estudiante +', onClick: () => this.createStudent() },
  ];

  constructor(
    private fb: FormBuilder,
    private crudService: CrudService,
    private dataService: DataService,
    private utilService: UtilService, 
    private sharedEventService: SharedEventService,
    private messageService: MessageService
  ) { }
  formulario!: FormGroup;

  ngOnInit(): void {
    this.university_id = this.getUniversityId();
    this.loadTablaData();
    this.loadGroups();
    this.loadCareers();
    this.form = this.fb.group({
      id: [''],
      name: ['', Validators.required],
      first_surname: ['', Validators.required],
      second_surname: [''],
      email: ['', [Validators.required, Validators.email]],
      phone: [''],
      student_number: ['', Validators.required],
      group_id: ['', Validators.required],
      career_id: ['', Validators.required]
    });
    this.sharedEventService.selectionChanged.subscribe((data: any[]) => {
      this.changeSelectedRows(data);
    });
  }
  
  loadTablaData(): void {
    this.showSpinner = true;
    let params = {class_id: null, multiple: false};
    let JSONUser = JSON.parse(localStorage.getItem('user')??"{}");
    let user = JSONUser.user;
    let params_empty = {};
    let params_to_use: any;
    let noClass = false
    // Students filter by class_id
    //params.class_id  = user.is_admin ? null : user.teacher_id;
    console.log("LOAD TABLE DATA: ", user.is_admin, user.teacher_id, params.class_id)
    if (!user.is_admin) {
      console.log("NO ES ADMIN")
      this.dataService.getTablaData({"university_id":this.university_id, "tablename":"class", "params":{"teacher_id":user.teacher_id} as any}).subscribe((data) => {
        const jsonString = JSON.stringify(data);
        const jsonObject = JSON.parse(jsonString);
        console.log("Clases del profesor", jsonObject)
        if (jsonObject.body.data == null || jsonObject.body.data.length == 0) {
          jsonObject.body.data = [];
        }
        if (jsonObject.body.data.length == 0) {
          // no hay clases
          params.class_id = 0 as any;
          params_to_use = params;
        } else if (jsonObject.body.data.length == 1) {
          // solo una clase
          params.class_id = jsonObject.body.data[0].id;
          params_to_use = params;
        } else {
          // todas las clases separadas por ,
          params.class_id = jsonObject.body.data.map((x: any) => x.id).join(",");
          params.multiple = true;
          params_to_use = params;
        }
        this.dataService.getTablaData({"university_id":this.university_id, "tablename":"student", "params":params_to_use as any}).subscribe((data) => {
          const jsonString = JSON.stringify(data);
          const jsonObject = JSON.parse(jsonString);
          console.log("Leegasd", jsonObject)
          if (jsonObject.body.data == null || jsonObject.body.data.length == 0) {
            jsonObject.body.data = [];
          }
          // Asignar datos para el grid
          let cleanData = this.dataService.processGridData(jsonObject);
          this.gridColumns = this.processGridColumns(cleanData.columns);
          this.gridData = cleanData.data;
          this.showSpinner = false;    
        });
      });
    } else {
      // all students
      console.log("SI ES ADMIN")
      this.dataService.getTablaData({"university_id":this.university_id, "tablename":"student", "params":{} as any}).subscribe((data) => {
        const jsonString = JSON.stringify(data);
        const jsonObject = JSON.parse(jsonString);
        if (jsonObject.body.data == null || jsonObject.body.data.length == 0) {
          jsonObject.body.data = [];
        }
        let cleanData = this.dataService.processGridData(jsonObject);
        this.gridColumns = this.processGridColumns(cleanData.columns);
        this.gridData = cleanData.data;
        this.showSpinner = false;    
      });
    }
  }

  processGridColumns(columns: Text[]): any[] {
    return columns.map(texto => ({
      header: this.utilService.translateColumn(texto.toString()),
      field: texto,
      editable: false,
      type: 'text',
      hide: texto.toString() === 'id'? true: false,
    }));
  }

  onSubmit(nextClose: boolean): void {
    if (this.form.valid) {
      this.showSpinner = true;
      const datosEstudiante = this.form.value;
        this.crudService.createRecord({"university_id": this.university_id, tablename: "student", "params": datosEstudiante as any}).subscribe((data: any) => {
          this.showSpinner = false;
          let response = JSON.parse(JSON.stringify(data));  
          this.form.reset();
          this.loadTablaData();
          if (response.body.status) {
            this.showAlert("Estudiante creado correctamente", "success")
            if (nextClose) {
              this.gridView();
            }
          } else {
            this.showAlert(response.body.message, "error")
          }
          /*if (nextClose) {
            if (response.body.status) {
              this.showAlert("Estudiante creado correctamente", "success")
            } else {
              this.showAlert(response.body.message, "error")
            }
            this.gridView();
          }*/
      });
    } else {
      this.showAlert("Complete todos los campos de forma valida", "error")
    }
  }
  updateStudentService(): void {
    if (this.form.valid) {
      this.showSpinner = true;
      const datosEstudiante = this.form.value;
        this.crudService.updateRecord({"university_id": this.university_id, tablename: "student", "params": datosEstudiante as any}).subscribe((data) => {
        console.log("response1: ", data)
        this.showSpinner = false;
        let response = JSON.parse(JSON.stringify(data));
        this.form.reset();
        this.loadTablaData();
        if (response.body.status) {
          this.showAlert("Estudiante actualizado correctamente", "success")
        } else {
          this.showAlert(response.body.message, "error")
        }          
        this.gridView();
      });
    } else {
      this.showAlert("Complete todos los campos de forma valida", "error")
    }    
  }  
  deleteDataService(): void {
    console.log("DELETE Students desde PUT")
    let datos = this.getSelectedIds()
    this.crudService.deleteRecord({"university_id": this.university_id, tablename: "student", "params": {"id":datos.toString()} as any}).subscribe((data) => {
      console.log('Respuesta del servidor:', data);
      this.form.reset();
      this.loadTablaData();
      this.showAlert("Estudiantes eliminados correctamente", "success")
      this.gridView();
    });   
  }  
  fillEditForm(): void {
    const selectedStudent = this.selectedRows[0];
    console.log("selectedStudent", selectedStudent)
    this.form.patchValue({
      id: selectedStudent.id,
      name: selectedStudent.name,
      first_surname: selectedStudent.first_surname,
      second_surname: selectedStudent.second_surname,
      student_number: selectedStudent.student_number,
      email: selectedStudent.email,
      phone: selectedStudent.phone,
      group_id: selectedStudent.class_id,
      career_id: selectedStudent.career_id
    });
  }

  loadGroups(): any {
    let params = {class_id: null, multiple: false};
    let JSONUser = JSON.parse(localStorage.getItem('user')??"{}");
    let user = JSONUser.user;
    if (!user.is_admin) {
      this.dataService.getTablaData({"university_id":this.university_id, "tablename":"class", "params":{"teacher_id":user.teacher_id} as any}).subscribe((data) => {
        const jsonString = JSON.stringify(data);
        const jsonObject = JSON.parse(jsonString);
        this.groups = jsonObject.body.data;
      });
    } else {
        // Cargar todos los grupos de la universidad
        this.dataService.getTablaData({"university_id":this.university_id, "tablename":"class", "params":{} as any}).subscribe((data) => {
          const jsonString = JSON.stringify(data);
          const jsonObject = JSON.parse(jsonString);
          this.groups = jsonObject.body.data;
        });
    }
  }

  loadCareers(): any {
    this.dataService.getTablaData({"university_id":this.university_id, "tablename":"career", "params":{} as any}).subscribe((data) => {
      console.log("Load careers", data)
      const jsonString = JSON.stringify(data);
      const jsonObject = JSON.parse(jsonString);
      this.careers = jsonObject.body.data;
    });

  }

  changeSelectedRows(selectedRows: any[]): void {
    console.log("DESDE STUDEN", selectedRows)
    this.selectedRows = selectedRows;
    if (selectedRows.length === 1) {
      this.gridEditView();
    } else if (selectedRows.length > 1) {
      this.deleteView();
    } else {
      this.gridView();
    }
  }
  gridEditView(): void {
    this.tituloDeLaVentana = 'Estudiantes';
    this.statusView = 'grid';
    this.listaDeBotones = [
      { texto: 'Editar Estudiante', onClick: () => this.editStudent() },
      { texto: 'Eliminar Estudiante', onClick: () => this.deleteStudents() },
    ];
  }
  deleteView(): void {
    this.tituloDeLaVentana = 'Estudiantes';
    this.statusView = 'grid';
    this.listaDeBotones = [
      { texto: 'Eliminar Estudiante', onClick: () => this.deleteStudents() },
    ];    
  }
  gridView(): void {
    this.form.reset();
    this.selectedRows = [];
    this.tituloDeLaVentana = 'Estudiantes';
    this.statusView = 'grid';
    this.listaDeBotones = [
      { texto: 'Nuevo Estudiante +', onClick: () => this.createStudent() },
    ];
  }
  formView(): void {
    this.tituloDeLaVentana = 'Nuevo Estudiante';
    this.statusView = 'form';
    this.listaDeBotones = [
      { texto: 'Guardar y volver a la lista', onClick: () => this.onSubmitAndBack() },
      { texto: 'Guardar y Crear nuevo', onClick: () => this.onSubmit(false) },
      { texto: '✕', onClick: () => this.gridView(), class: "btn-close"},
    ];
  }
  editView(): void {
    this.tituloDeLaVentana = 'Actualizar Estudiante';
    this.statusView = 'form';
    this.listaDeBotones = [
      { texto: 'Guardar Cambios', onClick: () => this.updateStudentService() },
      { texto: '✕', onClick: () => this.gridView(), class: "btn-close" },
    ];
  }
  onSubmitAndBack(): void {
    this.onSubmit(true);
  }
  createStudent(): void {
    this.formView();
  }
  editStudent(): void {
    console.log("editar estudiante")
    this.fillEditForm();
    this.editView();
  }
  deleteStudents(): void {
      // Pregunta al usuario si realmente desea eliminar las carreras
      const confirmDelete = window.confirm('¿Estás seguro de que deseas eliminar estos elementos?');
      // Si el usuario confirma, procede con la eliminación
      if (confirmDelete) {
        this.deleteDataService();
      }
  }
  getSelectedIds(): any[] {
    return this.selectedRows.map((row) => row.id);
  }  

  getUniversityId(): number {
    if (localStorage.getItem('user') != null) {
      let JSONUser = JSON.parse(localStorage.getItem('user')??"{}");
      let user = JSONUser.user;
      return user.university_id;
    } else {
      return 0;
    }
  }
  showAlert(message:any, type: any) {
    this.messageService.sendMessage({
      text: message,
      type: type,
      duration: 8000 
    });
  }  
}
