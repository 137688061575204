import { Component, OnInit } from '@angular/core';
import { DataService } from '../data.service';


@Component({
  selector: 'app-console',
  templateUrl: './console.component.html',
  styleUrls: ['./console.component.css']
})
export class ConsoleComponent implements OnInit {
  gridColumns: any[] = [];
  gridData: any[] = [];
  showSpinner: boolean = false;
  constructor(private dataService: DataService) {}
  ngOnInit(): void {
    console.log("Consola")
  }
  
  loadTablaData(): void {
    this.showSpinner = true;
    this.dataService.getTablaData({"university_id":1, "tablename":"student", "params":{} as any}).subscribe((data) => {
      console.log("data", data);
      const jsonString = JSON.stringify(data);
      const jsonObject = JSON.parse(jsonString);
      this.gridColumns = this.processGridColumns(jsonObject.body.columns);
      this.gridData = jsonObject.body.data;
      this.showSpinner = false;
    });
  }

  processGridColumns(columns: Text[]): any[] {
    return columns.map(texto => ({
      header: texto,
      field: texto,
      editable: false,
      type: 'text'
    }));
  }

}
