// shared-event.service.ts

import { Injectable, EventEmitter } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SharedEventService {
  selectionChanged = new EventEmitter<any[]>();
  private selectionChangeSource = new Subject<any[]>();
  selectionChange$ = this.selectionChangeSource.asObservable();

  notifySelectionChange(selectedRows: any[]): void {
    this.selectionChanged.emit(selectedRows);
  }

  notifySelectionReset(selection: any[]): void {
    this.selectionChangeSource.next(selection);
  }
}