import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class DataService {
  private apiUrl = 'tu/api/url/tabla';
  systemColumns: any[] = ["created_at", "updated_at", "university_id", "status", "student_id"];
  
  constructor(private http: HttpClient, private router: Router) {}

  public getTablaData(data: data): Observable<any[]> {
    //let url = 'https://wkvqd7vbe6.execute-api.us-east-1.amazonaws.com/QA/cl-university-console-backend/get_data'
    let url = 'https://wkvqd7vbe6.execute-api.us-east-1.amazonaws.com/PROD/cl-university-console-backend/get_data'
    data["params"] = this.removeNullKeys(data.params);
    return this.http.post(url, data, {
      headers: { 'content-type': 'application/json', 'authorization': localStorage.getItem('jwt')?.toString() ?? "" }
    }).pipe(
      map((response: any) => {
        if (response.statusCode === 401) {
          localStorage.removeItem('jwt');
          localStorage.removeItem('user');
          this.router.navigate(['/login']);
          window.location.reload();
        }
        return response;
      }),
      catchError((error) => {
        if (error.status === 401) {
        }
        // Propagar el error
        throw error;
      })
    );
  }

  public getSearchData(data: data): Observable<any[]> {
    //let url = 'https://wkvqd7vbe6.execute-api.us-east-1.amazonaws.com/QA/cl-university-console-backend/get_data'
    let url = 'https://wkvqd7vbe6.execute-api.us-east-1.amazonaws.com/PROD/cl-university-console-backend/get_search'
    data["params"] = this.removeNullKeys(data.params);
    return this.http.post(url, data, {
      headers: { 'content-type': 'application/json', 'authorization': localStorage.getItem('jwt')?.toString() ?? "" }
    }).pipe(
      map((response: any) => {
        if (response.statusCode === 401) {
          localStorage.removeItem('jwt');
          localStorage.removeItem('user');
          this.router.navigate(['/login']);
          window.location.reload();
        }
        return response;
      }),
      catchError((error) => {
        if (error.status === 401) {
        }
        // Propagar el error
        throw error;
      })
    );
  }

  public removeNullKeys(input: any) {
    for (const clave in input) {
      if (input.hasOwnProperty(clave)) {
        const valor = input[clave];
        if (valor === null) {
          delete input[clave];
        }
      }
    }
    return input;
  }

  processGridData(data: any): any {
    let clean_columns = this.removeSystemColumns(data.body.columns);
    let filtered_data = this.filterJsonArray(data.body.data, clean_columns);
    return {
      "columns": clean_columns,
      "data": filtered_data
    }
  }

  
  removeSystemColumns(inputColumns: string[]): string[] {
    // Filtra los elementos que no coinciden con systemColumns
    const filteredColumns = inputColumns.filter(column => !this.systemColumns.includes(column));
    return filteredColumns;
  } 

  filterJsonArray(inputJsonArray: any[], filtered_columns: string[]): any[] {
    // Filtra las claves de cada objeto JSON en el arreglo
    return inputJsonArray.map(jsonObj => {
      const filteredJson: any = {};
      Object.keys(jsonObj).forEach(key => {
        if (filtered_columns.includes(key)) {
          filteredJson[key] = jsonObj[key];
        }
      });
      return filteredJson;
    });
  }

}



export interface data {
  university_id: number;
  tablename: string;
  params: JSON;
}