<app-header [titulo]="tituloDeLaVentana" [botones]="listaDeBotones"></app-header>
<app-spinner *ngIf="showSpinner"></app-spinner>
<!-- VISTA FORMULARIO -->
<div class="teacher-form" *ngIf="statusView=='form'">
    <form [formGroup]="form" (ngSubmit)="onSubmit(false)">
      <!-- Primera Fila -->
      <div class="form-row">
        <div class="form-group col">
          <label for="name">Nombre</label>
          <input type="text" id="name" formControlName="name" required>
        </div>
        <div class="form-group col">
          <label for="first_surname">Apellido</label>
          <input type="text" id="first_surname" formControlName="first_surname" required>
        </div>
        <div class="form-group col">
          <label for="second_surname">Segundo Apellido</label>
          <input type="text" id="second_surname" formControlName="second_surname">
        </div>
      </div>
      
      <!-- Segunda Fila -->
      <div class="form-row">
        <div class="form-group col">
          <label for="email">Email</label>
          <input type="email" id="email" formControlName="email" required>
        </div>
        <div class="form-group col">
          <label for="phone">Teléfono</label>
          <input type="tel" id="phone" formControlName="phone">
        </div>
        <div class="form-group col"></div>
      </div>
  
      <!-- Botón de envío -->
      <div class="button-container" style="display:none;">
        <button type="submit">Guardar y Crear Nuevo</button>
      </div>
    </form>
  </div>
    <!-- VISTA LISTA DE ESTUDIANTES -->
    <app-grid [columns]="gridColumns" [data]="gridData" *ngIf="statusView=='grid'"></app-grid>

