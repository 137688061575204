import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  @Input() titulo: string = 'Título de la Ventana';
  @Input() subtitulo: string = '';
  @Input() botones: any[] = [];
  @Input() showFilterPopup: boolean = false;
  @Output() onClose = new EventEmitter<void>();
  @Output() onSubmits = new EventEmitter<any>();
  showPopup: boolean = false;
  displayFilterPopUp: boolean = false;
  constructor() { }

  ngOnInit(): void {
    console.log("HEADER INIT")
  }
  
  onButtonClick(button: any): void {
    // Lógica para manejar el clic en el botón
    button.onClick();
  }
  applyGridFilters(filters: { student: string; group: string }) {
    // on grid filters
  }
  onHidePopup() {
    this.onClose.emit();
    this.showFilterPopup = false;
  }
  onShowFilterPopup_() {
    // onshowfilterpopup
  }
  onDisplayPopUp(display: any) {
    // ondisplaypopup
  }
  onSubmit(params: any) {
    // onsubmited
    console.log("SUBMITED from header")
    this.onSubmits.emit(params);
  }
  
}
