<div class="sidebar">
    <div class="sidebar-logo" (click)="selectMenu('teacher')">
        <img src="https://contalink-files.s3.amazonaws.com/images/icon.png" alt="Icono" class="sidebar-icon">
    </div>

    
    <div class="sidebar-item" (click)="selectMenu('teacher')"  data-tooltip="Profesores" (mouseover)="isShowTeacherToolTip=true" (mouseout)="isShowTeacherToolTip = false">
        <img src="https://contalink-public-files-do-not-delete.s3.amazonaws.com/cl-university/teachers.png" alt="Icono" class="sidebar-icon">
    </div>
    <div *ngIf="isShowTeacherToolTip" class="sidebar-tooltip" id="teacher-tooltip" style="top:80px">Profesores</div>

    <div class="sidebar-item" (click)="selectMenu('class')" data-tooltip="Clases" (mouseover)="isShowClassToolTip=true" (mouseout)="isShowClassToolTip = false">
        <img src="https://contalink-public-files-do-not-delete.s3.amazonaws.com/cl-university/groups.png" alt="Icono" class="sidebar-icon">
    </div>
    <div *ngIf="isShowClassToolTip" class="sidebar-tooltip" id="class-tooltip" style="top:140px">Clases</div>

    <div class="sidebar-item" (click)="selectMenu('student')" data-tooltip="Estudiantes" (mouseover)="isShowStudentToolTip=true" (mouseout)="isShowStudentToolTip = false">
        <img src="https://contalink-public-files-do-not-delete.s3.amazonaws.com/cl-university/students.png" alt="Icono" class="sidebar-icon">
    </div>
    <div *ngIf="isShowStudentToolTip" class="sidebar-tooltip" id="student-tooltip" style="top:200px">Estudiantes</div>

    <div class="sidebar-item" (click)="selectMenu('company')" data-tooltip="Revisión" (mouseover)="isShowCompanyToolTip=true" (mouseout)="isShowCompanyToolTip = false">
        <img src="https://contalink-public-files-do-not-delete.s3.us-east-1.amazonaws.com/company.png" alt="Icono" class="sidebar-icon">
    </div>
    <div *ngIf="isShowCompanyToolTip" class="sidebar-tooltip" id="company-tooltip" style="top:200px">Revisión</div>

    <div class="sidebar-item" (click)="selectMenu('career')" data-tooltip="Carreras" (mouseover)="isShowCareerToolTip=true" (mouseout)="isShowCareerToolTip = false">
        <img src="https://contalink-public-files-do-not-delete.s3.amazonaws.com/cl-university/career.png" alt="Icono" class="sidebar-icon">
    </div>
    <div *ngIf="isShowCareerToolTip" class="sidebar-tooltip" id="career-tooltip"  style="top:260px">Carreras</div>

    <!-- Configuración -->
    <div class="sidebar-item" (click)="selectMenu('student')" data-tooltip="Configuración">
        <img src="https://contalink-public-files-do-not-delete.s3.amazonaws.com/cl-university/settings.png" alt="Icono" class="sidebar-icon">
    </div>
</div>


