import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Store } from '@ngrx/store';
import { map, catchError } from 'rxjs/operators';
import { HttpClient, HttpHeaders, HttpBackend } from '@angular/common/http';
import { FormControl, FormGroup } from '@angular/forms';
import { of } from 'rxjs';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';

/*import { environment } from "../../../../environments/environment";
import { DemoInvitationNavbarComponent } from '../../demo-invitation-navbar/demo-invitation-navbar.component';
import * as actionsNotif from "../../../core/store/notifications/notifications.actions";
import { PagenameService } from '../../../../app/services/pagename.service';
import { AppStore } from '../../../app.module';
import { AuthService, ProccessService, StoredProcedureService } from '../../../../app/services';
import { CookiesService } from '../../../../app/services/cookies.service';*/

@Component({
  selector: 'app-new-topbar',
  templateUrl: './new-topbar.component.html',
  styleUrls: ['./new-topbar.component.css']
})
export class NewTopbarComponent implements OnInit {
  @Input() user$: any = undefined
  @Input() message_lite: any = { value: '' }
  @Input() button_lite: any = undefined

  hide_sidebar_automatically: boolean = false
  pagetitle: string = ''
  userAccountToggleIsOpen: boolean = false
  universityName = 'Universidad'
  
  

  constructor(
    
    private modalService: BsModalService,
    private http: HttpClient,
    private handler: HttpBackend,
    private router: Router
    /*public authService: AuthService,
    private pageNameService: PagenameService,
    private CookiesService: CookiesService,
    private store: Store<AppStore>,
    private process: ProccessService,
    private sp: StoredProcedureService,*/
  ) { }

  ngOnInit(): void {
    if (localStorage.getItem('user') != null) {
      let JSONUser = JSON.parse(localStorage.getItem('user')??"{}");
      let user = JSONUser.user;
      this.universityName = user.university_identifier;
    }
    
  }

  displayMenu() {
    let tooltip = document.getElementById('tooltip');
    if (tooltip != null) {
      tooltip.style.display = (tooltip.style.display === 'block') ? 'none' : 'block';  
    }
  }

  logout() {
    localStorage.removeItem("jwt");
    localStorage.removeItem("user");
    this.router.navigate(['/login']).then(() => {
      window.location.reload();
    });
  }


}
