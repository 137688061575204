import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit{
  @Output() menuSelected = new EventEmitter<string>();
  isAdminSession: boolean = false;
  adminMenu = ["Profesores", "Carreras"]
  teacherMenu = ["Revisión"]

  isShowTeacherToolTip: boolean = false;
  isShowCareerToolTip: boolean = false;
  isShowStudentToolTip: boolean = false;
  isShowClassToolTip: boolean = false;
  isShowConfigToolTip: boolean = false;
  isShowCompanyToolTip: boolean = false;

  constructor(private router: Router) {}


  ngOnInit(): void {
    let JSONUser = JSON.parse(localStorage.getItem('user')??"{}");
    let user = JSONUser.user;
    this.isAdminSession = user.is_admin || false;
    this.attachTooltipEvents()
  }

  attachTooltipEvents() {
    const sidebarItems = document.querySelectorAll(".sidebar-item");
    //document.querySelectorAll(".sidebar-item")[0].dataset["tooltip"]
    sidebarItems.forEach((item) => {
      const sidebarItem = item as HTMLElement; 
      if (!this.isAdminSession) {
        if (this.adminMenu.includes(sidebarItem.dataset["tooltip"] as string)) {
          sidebarItem.classList.add("hidden-menu");
        }
      } else {
        // En sesiones de administrador, ocultar menus de profesor
        if (this.teacherMenu.includes(sidebarItem.dataset["tooltip"] as string)) {
          sidebarItem.classList.add("hidden-menu");
        }
      }
      /*

      
      item.addEventListener("mouseover", () => {
        const tooltipText = item.getAttribute("data-tooltip");
        this.showTooltip(sidebarItem, tooltipText?.toString() ?? "");
      });

      item.addEventListener("mouseout", () => {
        this.hideTooltip();
      });*/ 
    });
  }

  showTTip(event: MouseEvent, text: string) {
    this.isShowTeacherToolTip = true;
    const element = event.target as HTMLElement;
    var tooltip = document.createElement("div");
    tooltip.classList.add("tooltip");
    tooltip.innerHTML = text;
    tooltip.style.position = "absolute";
    tooltip.setAttribute("name", "tooltipSideBarElement")
    tooltip.setAttribute("*ngIf", "isShowTeacherToolTip");
    document.body.appendChild(tooltip);
    var rect = element.getBoundingClientRect();
    let top = rect.top + (element.offsetWidth/2)
    let left = 55
    tooltip.style.top = top + "px";
    tooltip.style.left = left + "px";
  }

  showTooltip(element: HTMLElement, text: string) {
    var tooltip = document.createElement("div");
    //tooltip.className = "tooltip";
    tooltip.classList.add("tooltip");
    tooltip.innerHTML = text;
    tooltip.style.position = "absolute";
    tooltip.setAttribute("name", "tooltipSideBarElement")

    document.body.appendChild(tooltip);

    var rect = element.getBoundingClientRect();
    //var top = rect.top - tooltip.offsetHeight - 5;
    //var left = rect.left + (element.offsetWidth - tooltip.offsetWidth) / 2;
    let top = rect.top + (element.offsetWidth/2)
    let left = 55
    tooltip.style.top = top + "px";
    tooltip.style.left = left + "px";
  }

  hideTooltip() {
    console.log("hideTooltip")
    //var tooltip = document.querySelector(".tooltip");
    var tooltip = document.body.querySelector(".tooltip")

    if (tooltip) {
        console.log("Si tooltip to remove")
        tooltip.remove();
    } else {
      console.log("No tooltip to remove")
    
    }
  }

  selectMenu(menu: string): void {
    this.menuSelected.emit(menu);
    this.router.navigate(['/'+menu]).then(() => {
      window.location.reload();
    });
  }
}