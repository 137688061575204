import { OnInit } from '@angular/core';
import { Component } from '@angular/core';
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { Router, NavigationStart, NavigationEnd, NavigationCancel, NavigationError } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent  implements OnInit {
  title = 'cl-university-console';
  jwt_prescence: boolean = false  
  showSpinner: boolean = false;
  
  constructor(private modalService: BsModalService, private router: Router) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this.showSpinner = true;
      } else if (
        event instanceof NavigationEnd ||
        event instanceof NavigationCancel ||
        event instanceof NavigationError
      ) {
        this.showSpinner = false;
      }
    });    
  }

  onMenuSelected(menu: string): void {
    // Lógica para manejar la selección del menú
  }

  ngOnInit(): void {
    this.jwt_prescence = localStorage.getItem("jwt") != null
    
  }
}



