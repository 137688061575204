<div class="toolbar" role="banner">
    <span style="margin-left:10px;width:500px;letter-spacing:1.5px;font-family: 'Lexend', sans-serif;">{{ universityName}}</span>
      <div class="spacer"></div>
      <a aria-label="Salir" target="_blank" rel="noopener" title="Cerrar Sesión" id="menuBtn" (click)="displayMenu()">
        <img src="https://contalink-public-files-do-not-delete.s3.amazonaws.com/cl-university/user.png" alt="Icono" class="topbar-icon">
        <div class="tooltip" id="tooltip">
          <ul>
            <li>Mi cuenta</li>
            <li (click)="logout()">Cerrar Sesión</li>
          </ul>
        </div>
      </a>
</div>
