import { Component, EventEmitter, Output, OnInit } from '@angular/core';
import { DataService } from '../data.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-filter-popup',
  templateUrl: './filter-popup.component.html',
  styleUrls: ['./filter-popup.component.css'],
})
export class FilterPopupComponent implements OnInit {
  student: string = '';
  group: string = '';
  university_id: number;
  @Output() onClose = new EventEmitter<void>();
  @Output() onApply = new EventEmitter<{ student: string; group: string; name: string }>();
  @Output() onSubmited = new EventEmitter<any>();
  groups: any[] = [];
  careers: any[] = [];
  students: any[] = [];
  filteredStudents: any[] = [];
  selectedStudentName: string = '';
  showSuggestions: boolean = false;
  form: FormGroup;

  empresas_base: any[] = [
    {"id":"INOVEX", "identifier":"TECNOLOGIA INOVEX"},
    {"id":"LIMPIEZAPRO", "identifier":"LIMPIEZAPRO"},
    {"id":"SAMANIEGO", "identifier":"ABOGADO SAMANIEGO"},
    {"id":"GALAUTO", "identifier":"TALLER GALAUTO"},
    {"id":"SEGUMEX", "identifier":"VIGILANCIA SEGUMEX"},
    {"id":"EXPRESS", "identifier":"COMEDOR EXPRESS"},
  ];


  constructor(
    private dataService: DataService,
    private fb: FormBuilder,
  ) { }
  formulario!: FormGroup;

  ngOnInit(): void {
    // init Event
    this.university_id = this.getUniversityId();
    this.loadGroups();
    this.loadCareers();
    this.loadStudents()
    this.form = this.fb.group({
      group_id: [''],
      empresa_base: [''],
      student: [''],
      name: ['']
    });
    
  }
  closePopup() {
    this.onClose.emit();
  }

  applyFilters() {
    console.log("filteremit") 
    this.onApply.emit({ student: this.student, group: this.group, name: "Julio" });
  }
  loadGroups(): any {
    let params = {class_id: null, multiple: false};
    let JSONUser = JSON.parse(localStorage.getItem('user')??"{}");
    let user = JSONUser.user;
    if (!user.is_admin) {
      this.dataService.getTablaData({"university_id":this.university_id, "tablename":"class", "params":{"teacher_id":user.teacher_id} as any}).subscribe((data) => {
        const jsonString = JSON.stringify(data);
        const jsonObject = JSON.parse(jsonString);
        this.groups = jsonObject.body.data;
      });
    } else {
        // Cargar todos los grupos de la universidad
        this.dataService.getTablaData({"university_id":this.university_id, "tablename":"class", "params":{} as any}).subscribe((data) => {
          const jsonString = JSON.stringify(data);
          const jsonObject = JSON.parse(jsonString);
          this.groups = jsonObject.body.data;
        });
    }
  }
  loadCareers(): any {
    this.dataService.getTablaData({"university_id":this.university_id, "tablename":"career", "params":{} as any}).subscribe((data) => {
      console.log("Load careers", data)
      const jsonString = JSON.stringify(data);
      const jsonObject = JSON.parse(jsonString);
      this.careers = jsonObject.body.data;
    });
  }
  loadStudents(): any {
    //this.showSpinner = true;
    let params = {class_id: null, multiple: false};
    let JSONUser = JSON.parse(localStorage.getItem('user')??"{}");
    let user = JSONUser.user;
    let params_empty = {};
    let params_to_use: any;
    let noClass = false
    this.dataService.getTablaData({"university_id":this.university_id, "tablename":"class", "params":{"teacher_id":user.teacher_id} as any}).subscribe((data) => {
      const jsonString = JSON.stringify(data);
      const jsonObject = JSON.parse(jsonString);
      console.log("Clases del profesor", jsonObject)
      if (jsonObject.body.data == null || jsonObject.body.data.length == 0) {
        jsonObject.body.data = [];
      }
      if (jsonObject.body.data.length == 0) {
        // no hay clases
        params.class_id = 0 as any;
        params_to_use = params;
      } else if (jsonObject.body.data.length == 1) {
        // solo una clase
        params.class_id = jsonObject.body.data[0].id;
        params_to_use = params;
      } else {
        // todas las clases separadas por ,
        params.class_id = jsonObject.body.data.map((x: any) => x.id).join(",");
        params.multiple = true;
        params_to_use = params;
      }
      this.dataService.getTablaData({"university_id":this.university_id, "tablename":"student", "params":params_to_use as any}).subscribe((data) => {
        const jsonString = JSON.stringify(data);
        const jsonObject = JSON.parse(jsonString);
        console.log("Leegasd", jsonObject)
        if (jsonObject.body.data == null || jsonObject.body.data.length == 0) {
          jsonObject.body.data = [];
        }
        // Asignar datos para el grid
        this.students = jsonObject.body.data;
        console.log("Students", this.students)
      });
    });    
  }  
  getUniversityId(): number {
    if (localStorage.getItem('user') != null) {
      let JSONUser = JSON.parse(localStorage.getItem('user')??"{}");
      let user = JSONUser.user;
      return user.university_id;
    } else {
      return 0;
    }
  }
  onSubmit(): void {
    console.log("onSubmit!", this.form.value.student)
    console.log("allstudents:", this.students);
    this.form.value.name = this.getStudentName(this.form.value.student);
    this.onClose.emit();
    this.onSubmited.emit(this.form.value);
  }  

  getStudentName(student_id: number): any {
    let student = this.students.find(x => x.id == student_id);
    if (typeof student == 'undefined') {
      return null;
    }
    return student?.name + " " + student?.first_surname + " " + student?.second_surname;
  }
  filterStudents(event: Event): void {
    const input = (event.target as HTMLInputElement).value.toLowerCase();
    this.filteredStudents = this.students.filter(student =>
      (`${student.name} ${student.first_surname} ${student.second_surname} (${student.student_number})`).toLowerCase().includes(input)
    );
    this.showSuggestions = true;
  }

  selectStudent(student: any): void {
    this.selectedStudentName = `${student.name} ${student.first_surname} ${student.second_surname} (${student.student_number})`;
    this.form.get('student')?.setValue(student.id);
    this.showSuggestions = false;
  }

  verifyStudent(): void {
    const match = this.students.find(student =>
      this.selectedStudentName === `${student.name} ${student.first_surname} ${student.second_surname} (${student.student_number})`
    );

    if (!match) {
      this.selectedStudentName = '';
      this.form.get('student')?.setValue(null);
    }
  }
}
