import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { ConsoleComponent } from './console/console.component';
import { NewTopbarComponent } from './new-topbar/new-topbar.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { SidebarComponent } from './sidebar/sidebar.component';
import { SpinnerComponent } from './spinner/spinner.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { GridComponent } from './grid/grid.component';
import { TeacherComponent } from './teacher/teacher.component';
import { ClassComponent } from './class/class.component';
import { StudentComponent } from './student/student.component';
import { FormComponent } from './form/form.component';
import { ReactiveFormsModule } from '@angular/forms';
import { CareerComponent } from './career/career.component';
import { HeaderComponent } from './header/header.component';
import { MessageComponent } from './message/message.component';
import { CompanyComponent } from './company/company.component';
import { FilterPopupComponent } from './filter-popup/filter-popup.component';


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ConsoleComponent,
    NewTopbarComponent,
    SidebarComponent,
    SpinnerComponent,
    GridComponent,
    TeacherComponent,
    ClassComponent,
    StudentComponent,
    FormComponent,
    CareerComponent,
    HeaderComponent,
    MessageComponent,
    CompanyComponent,
    FilterPopupComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    ModalModule.forRoot(),
    BrowserAnimationsModule,
    ReactiveFormsModule,

  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
