// message.component.ts

import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { MessageService } from '../message.service';

@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.css']
})
export class MessageComponent implements OnInit, OnDestroy {
  message: any;
  subscription: Subscription;

  constructor(private messageService: MessageService) {}

  ngOnInit() {
    this.subscription = this.messageService.getMessage().subscribe(message => {
      this.message = message;

      // Reinicia las clases antes de mostrar el mensaje
      this.resetClasses();

      // Agrega la clase fade-in para mostrar gradualmente el mensaje
      setTimeout(() => {
        this.messageFadeIn();
      }, 100);

      setTimeout(() => {
        this.messageFadeOut();
      }, (this.message.duration || 5000) - 1000); // Inicia el desvanecimiento 1 segundo antes de la desaparición
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  private resetClasses() {
    if (this.message) {
      const messageElement = document.querySelector('.message');
      if (messageElement) {
        messageElement.classList.remove('fade-in', 'fade-out');
      }
    }
  }

  private messageFadeIn() {
    if (this.message) {
      const messageElement = document.querySelector('.message');
      if (messageElement) {
        messageElement.classList.add('fade-in');
      }
    }
  }

  private messageFadeOut() {
    if (this.message) {
      const messageElement = document.querySelector('.message');
      if (messageElement) {
        messageElement.classList.add('fade-out');

        // Limpia el mensaje después de que se completa la animación
        setTimeout(() => {
          this.message = null;
        }, 500); // Ajusta el tiempo según la duración de la animación
      }
    }
  }
}
