<app-header [titulo]="tituloDeLaVentana" [botones]="listaDeBotones"></app-header>
<app-spinner *ngIf="showSpinner"></app-spinner>
<!-- VISTA FORMULARIO -->
<div class="class-form" *ngIf="statusView=='form'">
    <form [formGroup]="form" (ngSubmit)="onSubmit(false)">
      <!-- Primera Fila -->
      <div class="form-row">
        <div class="form-group col">
          <label for="name">Nombre</label>
          <input type="text" id="identifier" formControlName="identifier" required>
        </div>
        <div class="form-group col">
            <label for="teacher_id">Maestro</label>
            <select id="teacher_id" formControlName="teacher_id"  [attr.disabled]="!isadmin ? true : null" required>
              <option [ngValue]="null" disabled>Selecciona un maestro</option>
              <option *ngFor="let teacher of teachers" [ngValue]="teacher.id">{{ teacher.name }} {{ teacher.first_surname }} {{ teacher.second_surname }}</option>
            </select>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col">
            <label for="classroom">Salón/Grupo</label>
            <input type="text" id="classroom" formControlName="classroom">
        </div>        
        <div class="form-group col">
            <label for="start_date">Fecha de Inicio</label>
            <input type="date" id="start_date" formControlName="start_date" required>
          </div>
          <div class="form-group col">
            <label for="end_date">Fecha de Fin</label>
            <input type="date" id="end_date" formControlName="end_date" required>
          </div>        
      </div>
  
      <!-- Botón de envío -->
      <div class="button-container" style="display:none;">
        <button type="submit">Guardar y Crear Nuevo</button>
      </div>
    </form>
  </div>
<app-grid [columns]="gridColumns" [data]="gridData" *ngIf="statusView=='grid'"></app-grid>