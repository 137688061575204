import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CrudService } from '../crud.service';

@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.css']
})
export class FormComponent implements OnInit {

  constructor(private fb: FormBuilder, private crudService: CrudService) { }
  form!: FormGroup;
  @Input() properties: any[] = [];

  ngOnInit(): void {
    
  }

  onSubmit(): void {
    if (this.form.valid) {
      //const nuevoRegistro: Registro = this.form.value;
      //this.crudService.createRecord({}).subscribe(() => {
        // manejar el éxito de la creación del registro
      //});
    }
  }

}