import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-spinner',
  template: `
  <div class="spinner-overlay">
    <div class="spinner"></div>
  </div>`,
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.css']
})
export class SpinnerComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    console.log("spinner!");
    /*
    const spinnerOverlay = document.querySelector('.spinner-overlay') as HTMLElement;
    setTimeout(() => {
      // Oculta el spinner después de 5 segundos
      if (spinnerOverlay != null) {
        console.log("spinner out!")
        spinnerOverlay.style.display = 'none';
      }
    }, 5000);
    */
  }

}
