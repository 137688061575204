import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { ConsoleComponent } from './console/console.component';
import { AuthguardGuard } from './authguard.guard';
import { StudentComponent } from './student/student.component';
import { ClassComponent } from './class/class.component';
import { TeacherComponent } from './teacher/teacher.component';
import { CareerComponent } from './career/career.component';
import { CompanyComponent } from './company/company.component';

const routes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: 'student', component: StudentComponent , canActivate: [AuthguardGuard]},
  { path: 'class', component: ClassComponent , canActivate: [AuthguardGuard]},
  { path: 'teacher', component: TeacherComponent , canActivate: [AuthguardGuard]},
  { path: 'career', component: CareerComponent, canActivate: [AuthguardGuard]},
  { path: 'console', component: CompanyComponent, canActivate: [AuthguardGuard]}, // ConsoleComponent
  { path: 'company', component: CompanyComponent, canActivate: [AuthguardGuard]},
  { path: '**', redirectTo: '/teacher', pathMatch: 'full' }, 
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
