<div class="table-container">
  <table class="table">
    <thead>
      <tr>
        <th *ngFor="let column of columns" class="grid-header"
        [ngClass]="{'hidden-column': column.hide, 'grid-header-text': column.type === 'text' || column.type === 'link', 'grid-header-number': column.type === 'number', 'grid-header-list': column.type === 'list'}"
        >{{ column.header }}
        </th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let row of data" class="grid-row" [class.selected]="isSelected(row)" (click)="toggleRowSelection(row)">
        <td *ngFor="let column of columns" class="grid-cell" [ngClass]="{'hidden-column': column.hide}">
          <ng-container *ngIf="column.editable; else displayValue">
            <input [(ngModel)]="row[column.field]" *ngIf="column.type !== 'list'" />
            <select [(ngModel)]="row[column.field]" *ngIf="column.type === 'list'" class="full-width">
              <option *ngFor="let option of column.options" [value]="option">{{ option }}</option>
            </select>
          </ng-container>
          <ng-template #displayValue>
            <span *ngIf="column.type !== 'link'">{{ getSanitizedValue(row[column.field], column.field) }}</span>
            <span *ngIf="column.type === 'link'"><a class="grid-link-cell" target="_blank" href="{{column.url}}{{ getSanitizedValue(row[column.field], column.field) }}">{{ getSanitizedValue(row[column.field], column.field) }}</a></span>
          </ng-template>
        </td>
      </tr>
    </tbody>
  </table>
</div>
