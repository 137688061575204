import { Component, OnInit, Input } from '@angular/core';
import { DataService } from '../data.service';
import { Data } from '@angular/router';
import { SharedEventService } from '../shared-event.service';

@Component({
  selector: 'app-grid',
  templateUrl: './grid.component.html',
  styleUrls: ['./grid.component.css']
})
export class GridComponent implements OnInit {
  university_id: number;
  @Input() columns: any[] = [];
  @Input() data: any[] = [];
  selectedRows: any[] = [];


  teachers: any[] = [];
  allClass: any[] = [];
  careers: any[] = [];
  constructor(private dataService: DataService, private sharedEventService: SharedEventService) { 
    this.sharedEventService.selectionChange$.subscribe(selection => {
      this.selectedRows = selection;
    });
  }
  

  ngOnInit(): void {
    this.university_id = this.getUniversityId();
    this.loadTeachers()
    this.loadClass()
    this.loadCareer()
  }

  getSanitizedValue(input: any, columname: any): any {
    if (columname.endsWith("_id")) {
      if (columname === 'teacher_id') {
        console.log("Sanitizing", input, columname)
        return this.getTeacherName(input);
      }
      if (columname === 'class_id') {
        return this.getClassName(input);
      }      
      if (columname === 'career_id') {
        return this.getCareerName(input);
      }
    }

    return input ? input : '';
  }
  getTeacherName(teacher_id: any): string {
    const teacher = this.teachers.find(teacher => teacher.id === teacher_id);
    return teacher ? teacher.name + ' ' + teacher.first_surname + ' ' + teacher.second_surname : '';
  }
  getClassName(class_id: any): string {
    const classe = this.allClass.find(classe => classe.id === class_id);
    return classe ? classe.identifier  : '';
  }  
  getCareerName(career_id: any): string {
    const career = this.careers.find(career => career.id === career_id);
    return career ? career.name + ' ' + career.identifier : '';
  }
  loadTeachers(): any {
    this.dataService.getTablaData({"university_id":this.university_id, "tablename":"teachers", "params":{} as any}).subscribe((data) => {
      const jsonString = JSON.stringify(data);
      const jsonObject = JSON.parse(jsonString);
      this.teachers = jsonObject.body.data;
    });
  }
  loadClass(): any {
    this.dataService.getTablaData({"university_id":this.university_id, "tablename":"class", "params":{} as any}).subscribe((data) => {
      const jsonString = JSON.stringify(data);
      const jsonObject = JSON.parse(jsonString);
      this.allClass = jsonObject.body.data;
    });
  }  
  loadCareer(): any {
    this.dataService.getTablaData({"university_id":this.university_id, "tablename":"career", "params":{} as any}).subscribe((data) => {
      const jsonString = JSON.stringify(data);
      const jsonObject = JSON.parse(jsonString);
      this.careers = jsonObject.body.data;
    });
  }

  toggleRowSelection(row: any): void {
    const index = this.selectedRows.indexOf(row);
    if (index === -1) {
      this.selectedRows.push(row);
    } else {
      this.selectedRows.splice(index, 1);
    }
    this.sharedEventService.notifySelectionChange(this.selectedRows);

  }
  
  isSelected(row: any): boolean {
    return this.selectedRows.includes(row);
  }
  
  getUniversityId(): number {
    if (localStorage.getItem('user') != null) {
      let JSONUser = JSON.parse(localStorage.getItem('user')??"{}");
      let user = JSONUser.user;
      return user.university_id;
    } else {
      return 0;
    }
  }  

}