import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UtilService {


  public translateColumn(column: string): string {
    // Traduce el nombre de la columna
    switch (column) {
      case 'identifier':
        return 'Identificador';
      case 'name':
        return 'Nombre';
      case 'first_surname':
        return 'Primer Apellido';
      case 'second_surname':
        return 'Segundo Apellido';
      case 'email':
        return 'Correo Electrónico';
      case 'phone':
        return 'Teléfono';
      case 'student_number':
        return 'Matrícula';        
      case 'career_id':
        return 'Carrera';
      case 'class_id':
        return 'Clase';
      case 'status':
        return 'Estado';
      case 'start_date':
        return 'Fecha de Inicio';
      case 'end_date':
        return 'Fecha de Fin';
      case 'teacher_id':
        return 'Profesor';
      case 'classroom':
        return 'Salón/Grupo';
      case 'student':
        return 'Alumno';
      case 'company':
        return 'Empresa';
      // Agrega más casos según sea necesario
      default:
        return column;
    }
  }

  public translateColumns(inputColumns: string[]): string[] {
    // Traduce los nombres de las columnas
    return inputColumns.map(column => {
      switch (column) {
        case 'identifier':
          return 'Identificador';
        case 'name':
          return 'Nombre';
        case 'first_surname':
          return 'Primer Apellido';
        case 'second_surname':
          return 'Segundo Apellido';
        case 'email':
          return 'Correo Electrónico';
        case 'phone':
          return 'Teléfono';
        case 'student_number':
          return 'Matrícula';          
        case 'career_id':
          return 'ID de Carrera';
        case 'class_id':
          return 'ID de Clase';
        case 'status':
          return 'Estado';
        case 'start_date':
          return 'Fecha de Inicio';
        case 'end_date':
          return 'Fecha de Fin';
        case 'teacher_id':
          return 'ID de Profesor';
        case 'classroom':
          return 'Salón/Grupo';
        // Agrega más casos según sea necesario
        default:
          return column;
      }
    });
  }

  constructor() { }
}
