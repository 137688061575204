<div class="background-image">
  <app-spinner *ngIf="showSpinner"></app-spinner>
  <div class="login-container">
    <img src="https://contalink-public-files-do-not-delete.s3.amazonaws.com/contalink-logo.svg" alt="Contalink" class="logo">
      <form (ngSubmit)="login()" class="login-form">
        <!--<label for="username">Username:</label>-->
        <input type="text" id="username" [(ngModel)]="username" name="username" placeholder="usuario" required>
    
        <!--<label for="password">Password:</label>-->
        <input type="password" id="password" [(ngModel)]="password" name="password" placeholder="contraseña" required>
    
        <button type="submit">Ingresar</button>

        <!-- Elemento para mostrar mensajes de error -->
        <div class="error-message" *ngIf="message">{{ message }}</div>
      </form>
</div>

<!--<p>login works!</p>
<form (ngSubmit)="login()">
  <label for="username">Username:</label>
  <input type="text" id="username" [(ngModel)]="username" name="username" required>

  <label for="password">Password:</label>
  <input type="password" id="password" [(ngModel)]="password" name="password" required>

  <button type="submit">Login</button>




font-family: 'Lexend', sans-serif;
    background-image: url('https://contalink-public-files-do-not-delete.s3.amazonaws.com/cl-university/login-background.avif'); /* Reemplaza 'ruta_de_tu_imagen.jpg' con la ruta o URL de tu imagen */
    background-size: cover; /* Ajusta el tamaño de la imagen para cubrir todo el contenedor */
    background-position: center; /* Centra la imagen en el contenedor */
    background-repeat: no-repeat; /* Evita que la imagen se repita */














</form>-->