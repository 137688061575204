import { Component, OnInit } from '@angular/core';
import { DataService } from '../data.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CrudService } from '../crud.service';
import { UtilService } from '../util.service';
import { SharedEventService } from '../shared-event.service';
import { MessageService } from '../message.service';

@Component({
  selector: 'app-teacher',
  templateUrl: './teacher.component.html',
  styleUrls: ['./teacher.component.css']
})
export class TeacherComponent implements OnInit {
  university_id: number;
  gridColumns: any[] = [];
  gridData: any[] = [];
  selectedRows: any[] = [];
  showSpinner: boolean = false;
  form: FormGroup;
  statusView: string = 'grid';
  tituloDeLaVentana: string = 'Profesores';
  listaDeBotones: any[] = [
    { texto: 'Nuevo Profesor +', onClick: () => this.createTeacher() },
  ];

  constructor(
    private fb: FormBuilder, 
    private crudService: CrudService, 
    private dataService: DataService, 
    private utilService: UtilService,
    private sharedEventService: SharedEventService,
    private messageService: MessageService
    ) { }
  ngOnInit(): void {
    this.university_id = this.getUniversityId();
    this.loadTablaData();
    this.form = this.fb.group({
      id: [''],
      name: ['', Validators.required],
      first_surname: ['', Validators.required],
      second_surname: [''],
      email: ['', [Validators.required, Validators.email]],
      phone: ['']
    });    
    this.sharedEventService.selectionChanged.subscribe((data: any[]) => {
      this.changeSelectedRows(data);
    });
  }
  
  loadTablaData(): void {
    this.showSpinner = true;
    this.dataService.getTablaData({"university_id":this.university_id, "tablename":"teachers", "params":{} as any}).subscribe((data) => {
      const jsonString = JSON.stringify(data);
      const jsonObject = JSON.parse(jsonString);
      if (jsonObject.body.data == null) {
        jsonObject.body.data = [];
      }
      let cleanData = this.dataService.processGridData(jsonObject);
      this.gridColumns = this.processGridColumns(cleanData.columns);
      this.gridData = cleanData.data;
      this.showSpinner = false;      
    });
  }

  processGridColumns(columns: Text[]): any[] {
    return columns.map(texto => ({
      header: this.utilService.translateColumn(texto.toString()),
      field: texto,
      editable: false,
      type: 'text',
      hide: texto.toString() === 'id'? true: false,
    }));
  }

  onSubmit(nextClose: boolean): void {
    if (this.form.valid) {
      this.showSpinner = true;
      const datosProfesor = this.form.value;
      // Aquí puedes enviar los datos a tu servicio o hacer lo que necesites
        this.crudService.createRecord({"university_id": this.university_id, tablename: "teachers", "params": datosProfesor as any}).subscribe((data) => {
        this.showSpinner = false;
        let response = JSON.parse(JSON.stringify(data));  
        this.form.reset();
        this.loadTablaData();
        if (response.body.status) {
          this.showAlert("Profesor creado correctamente", "success")
          if (nextClose) {
            this.gridView();
          }
        } else {
          this.showAlert(response.body.message, "error")
        }



        /*if (nextClose) {
          if (response.body.status) {
            this.showAlert("Profesor creado correctamente", "success")
          } else {
            this.showAlert(response.body.message, "error")
          }
          this.gridView();
        }*/
      });
    } else {
      this.showAlert("Complete todos los campos de forma valida", "success")
    }
  }
  updateDataService(): void {
    if (this.form.valid) {
      const datos = this.form.value;
        this.crudService.updateRecord({"university_id": this.university_id, tablename: "teachers", "params": datos as any}).subscribe((data) => {
        this.form.reset();
        this.loadTablaData();
        this.showAlert("Profesor actualizado correctamente", "success")
        this.gridView();
      });
    } else {
      alert("Complete todos los campos de forma valida")
    }    
  }
  deleteDataService(): void {
    console.log("DELETE Teachers desde PUT")
    let datos = this.getSelectedIds()
    this.crudService.deleteRecord({"university_id": this.university_id, tablename: "teachers", "params": {"id":datos.toString()} as any}).subscribe((data) => {
      console.log('Respuesta del servidor:', data);
      this.form.reset();
      this.loadTablaData();
      this.showAlert("Profesor eliminado correctamente", "success")
      this.gridView();
    });   
  }
  fillEditForm(): void {
    const selectedRow = this.selectedRows[0];
    this.form.patchValue({
      id: selectedRow.id,
      name: selectedRow.name,
      first_surname: selectedRow.first_surname,
      second_surname: selectedRow.second_surname,
      email: selectedRow.email,
      phone: selectedRow.phone,
    });
  }     
  changeSelectedRows(selectedRows: any[]): void {
    this.selectedRows = selectedRows;
    if (selectedRows.length === 1) {
      this.gridEditView();
    } else if (selectedRows.length > 1) {
      this.deleteView();
    } else {
      this.gridView();
    }
  }
  gridEditView(): void {
    this.tituloDeLaVentana = 'Profesores';
    this.statusView = 'grid';
    this.listaDeBotones = [
      { texto: 'Editar Profesor', onClick: () => this.editStudent() },
      { texto: 'Eliminar Profesor', onClick: () => this.deleteStudents() },
    ];
  }
  deleteView(): void {
    this.tituloDeLaVentana = 'Profesores';
    this.statusView = 'grid';
    this.listaDeBotones = [
      { texto: 'Eliminar Profesor', onClick: () => this.deleteStudents() },
    ];    
  }
  gridView(): void {
    this.form.reset();
    this.selectedRows = [];
    this.tituloDeLaVentana = 'Profesores';
    this.statusView = 'grid';
    this.listaDeBotones = [
      { texto: 'Nuevo Profesor +', onClick: () => this.createTeacher() },
    ];
  }
  formView(): void {
    this.tituloDeLaVentana = 'Nuevo Profesor';
    this.statusView = 'form';
    this.listaDeBotones = [
      { texto: 'Guardar y volver a la lista', onClick: () => this.onSubmitAndBack() },
      { texto: 'Guardar y Crear nuevo', onClick: () => this.onSubmit(false) },
      { texto: '✕', onClick: () => this.gridView(), class: "btn-close" },
    ];
  }
  editView(): void {
    this.tituloDeLaVentana = 'Actualizar Profesor';
    this.statusView = 'form';
    this.listaDeBotones = [
      { texto: 'Guardar Cambios', onClick: () => this.updateDataService() },
      { texto: '✕', onClick: () => this.gridView(), class: "btn-close" },
    ];
  }
  onSubmitAndBack(): void {
    this.onSubmit(true);
  }
  createTeacher(): void {
    this.formView();
  }
  editStudent(): void {
    console.log("editar Profesor")
    this.fillEditForm();
    this.editView();
  }
  deleteStudents(): void {
    // Pregunta al usuario si realmente desea eliminar las carreras
    const confirmDelete = window.confirm('¿Estás seguro de que deseas eliminar estos elementos?');
    // Si el usuario confirma, procede con la eliminación
    if (confirmDelete) {
      this.deleteDataService();
    }
  }
  getSelectedIds(): any[] {
    return this.selectedRows.map((row) => row.id);
  }
  getUniversityId(): number {
    if (localStorage.getItem('user') != null) {
      let JSONUser = JSON.parse(localStorage.getItem('user')??"{}");
      let user = JSONUser.user;
      return user.university_id;
    } else {
      return 0;
    }
  }
  showAlert(message:any, type: any) {
    this.messageService.sendMessage({
      text: message,
      type: type,
      duration: 8000 
    });
  }
}
