import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthguardGuard implements CanActivate {

  constructor(private authService: AuthService, private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    console.log("ON GUARDS!")
    let JSONUser = JSON.parse(localStorage.getItem('user')??"{}");
    let user = JSONUser.user;
    let adminRoutes = ['/teacher', '/config', '/career'];
    let teacherRoutes = ['/company'];
    const currentUrl: string = state.url;
    if (localStorage.getItem('user') == null) {
      this.router.navigate(['/login']);
      return false;
    } else {
      // validar si el usuario tiene permiso
      if (!user.is_admin && adminRoutes.includes(state.url)) {
        this.router.navigate(['/console']);
        return false;
      } else if (user.is_admin && teacherRoutes.includes(state.url)) {
        this.router.navigate(['/console']);
        return false
      } else { 
        return true;
      }
    }
  }
  
}
