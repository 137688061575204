// message.service.ts

import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MessageService {
  private messageSubject = new Subject<any>();

  sendMessage(message: any) {
    this.messageSubject.next(message);
  }

  getMessage() {
    return this.messageSubject.asObservable();
  }
}
