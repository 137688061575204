import { Component, OnInit } from '@angular/core';
import { DataService } from '../data.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CrudService } from '../crud.service';
import { UtilService } from '../util.service';
import { SharedEventService } from '../shared-event.service';
import { MessageService } from '../message.service';

@Component({
  selector: 'app-class',
  templateUrl: './class.component.html',
  styleUrls: ['./class.component.css']
})
export class ClassComponent implements OnInit {
  university_id: number;
  gridColumns: any[] = [];
  gridData: any[] = [];
  selectedRows: any[] = [];
  teachers: any[] = [];
  showSpinner: boolean = false;
  form: FormGroup;
  teacher_id: any;
  isadmin: boolean = false;
  statusView: string = 'grid';
  tituloDeLaVentana: string = 'Clases';
  listaDeBotones: any[] = [
    { texto: 'Nueva Clase +', onClick: () => this.createClass() },
  ];

  constructor(
    private fb: FormBuilder, 
    private crudService: CrudService, 
    private dataService: DataService, 
    private utilService: UtilService,
    private sharedEventService: SharedEventService,
    private messageService: MessageService
  ) { }
  formulario!: FormGroup;

  ngOnInit(): void {
    this.university_id = this.getUniversityId();
    this.teacher_id = JSON.parse(localStorage.getItem('user')??"{}").user.teacher_id;
    this.loadTeachers();
    this.loadTablaData();
    let JSONUser = JSON.parse(localStorage.getItem('user')??"{}");
    let user = JSONUser.user;
    this.isadmin = user.is_admin;
    
    this.form = this.fb.group({
      id: [''],
      identifier: ['', Validators.required],
      teacher_id: [this.teacher_id, Validators.required],
      classroom: [''],
      start_date: [null, Validators.required], // Campo de fecha start_date
      end_date: [null, Validators.required]   // Campo de fecha end_date
    });
    this.sharedEventService.selectionChanged.subscribe((data: any[]) => {
      this.changeSelectedRows(data);
    });    
  }
  
  loadTablaData(): void {
    this.showSpinner = true;
    let params = {teacher_id: null};
    let JSONUser = JSON.parse(localStorage.getItem('user')??"{}");
    let user = JSONUser.user;
    // class filter by teacher_id
    params.teacher_id  = user.is_admin ? null : user.teacher_id;
    this.dataService.getTablaData({"university_id":this.university_id, "tablename":"class", "params":params as any}).subscribe((data) => {
      const jsonString = JSON.stringify(data);
      const jsonObject = JSON.parse(jsonString);
      console.log("Class:", jsonObject)
      if (jsonObject.body.data == null) {
        jsonObject.body.data = [];
      }
      let cleanData = this.dataService.processGridData(jsonObject);
      this.gridColumns = this.processGridColumns(cleanData.columns);
      this.gridData = cleanData.data;
      this.showSpinner = false;
    });
  }

  processGridColumns(columns: Text[]): any[] {
    return columns.map(texto => ({
      header: this.utilService.translateColumn(texto.toString()),
      field: texto,
      editable: false,
      type: 'text',
      hide: texto.toString() === 'id'? true: false,
    }));
  }
  onSubmit(nextClose: boolean): void {
    if (this.form.valid) {
      const datosClass = this.form.value;
      console.log('Datos de la clase:', datosClass);
      // Aquí puedes enviar los datos a tu servicio o hacer lo que necesites
        this.crudService.createRecord({"university_id": this.university_id, tablename: "class", "params": datosClass as any}).subscribe((data) => {
        console.log('Respuesta del servidor:', data);
        this.form.reset();
        this.loadTablaData();
        if (nextClose) {
          this.showAlert("Clase creada correctamente", "success")
          this.gridView();
        }
      });
    } else {
      this.showAlert("Complete todos los campos de forma valida", "error")
    }
  }
  updateDataService(): void {
    console.log("Actualizar Class desde PUT")
    if (this.form.valid) {
      const datos = this.form.value;
        this.crudService.updateRecord({"university_id": this.university_id, tablename: "class", "params": datos as any}).subscribe((data) => {
        console.log('Respuesta del servidor:', data);
        this.form.reset();
        this.loadTablaData();
        this.gridView();
      });
    } else {
      this.showAlert("Complete todos los campos de forma valida", "error")
    }    
  }
  deleteDataService(): void {
    console.log("DELETE Class desde PUT")
    let datos = this.getSelectedIds()
    this.crudService.deleteRecord({"university_id": this.university_id, tablename: "class", "params": {"id":datos.toString()} as any}).subscribe((data) => {
      console.log('Respuesta del servidor:', data);
      this.form.reset();
      this.loadTablaData();
      this.showAlert("Clase eliminada correctamente", "success")
      this.gridView();
    });   
  }   
  fillEditForm(): void {
    const selectedRow = this.selectedRows[0];
    console.log("selectedRow", this.formatDateDMY(selectedRow.start_date))
    this.form.patchValue({
      id: selectedRow.id,
      identifier: selectedRow.identifier,
      teacher_id: selectedRow.teacher_id,
      classroom: selectedRow.classroom,
      start_date: new Date(this.formatDateDMY(selectedRow.start_date)),
      end_date: new Date(this.formatDateDMY(selectedRow.end_date))
    });
  }

  getTeacherName(teacher_id: any): string {
    const teacher = this.teachers.find(teacher => teacher.teacher_id === teacher_id);
    return teacher ? teacher.name + ' ' + teacher.first_surname + ' ' + teacher.second_surname : '';
  }
  loadTeachers(): any {
    this.dataService.getTablaData({"university_id":this.university_id, "tablename":"teachers", "params":{} as any}).subscribe((data) => {
      const jsonString = JSON.stringify(data);
      const jsonObject = JSON.parse(jsonString);
      console.log('Teachers:', jsonObject)
      this.teachers = jsonObject.body.data;
    });
  }
  changeSelectedRows(selectedRows: any[]): void {
    this.selectedRows = selectedRows;
    console.log("DESDE STUDEN", selectedRows)
    if (selectedRows.length === 1) {
      this.gridEditView();
    } else if (selectedRows.length > 1) {
      this.deleteView();
    } else {
      this.gridView();
    }
  }
  gridEditView(): void {
    this.tituloDeLaVentana = 'Clases';
    this.statusView = 'grid';
    this.listaDeBotones = [
      { texto: 'Editar Clase', onClick: () => this.editStudent() },
      { texto: 'Eliminar Clase', onClick: () => this.deleteClasses() },
    ];
  }
  deleteView(): void {
    this.tituloDeLaVentana = 'Clases';
    this.statusView = 'grid';
    this.listaDeBotones = [
      { texto: 'Eliminar Clase', onClick: () => this.deleteClasses() },
    ];    
  }
  gridView(): void {
    this.form.reset();
    this.selectedRows = [];
    this.sharedEventService.notifySelectionReset([]);
    this.tituloDeLaVentana = 'Clases';
    this.statusView = 'grid';
    this.listaDeBotones = [
      { texto: 'Nueva Clase +', onClick: () => this.createClass() },
    ];
  }
  formView(): void {
    this.tituloDeLaVentana = 'Nueva Clase';
    this.statusView = 'form';
    this.listaDeBotones = [
      { texto: 'Guardar y volver a la lista', onClick: () => this.onSubmitAndBack() },
      { texto: 'Guardar y Crear nuevo', onClick: () => this.onSubmit(false) },
      { texto: '✕', onClick: () => this.gridView(), class: "btn-close" },
    ];
  }
  editView(): void {
    this.tituloDeLaVentana = 'Actualizar Clase';
    this.statusView = 'form';
    this.listaDeBotones = [
      { texto: 'Guardar Cambios', onClick: () => this.updateDataService() },
      { texto: '✕', onClick: () => this.gridView(), class: "btn-close" },
    ];
  }
  onSubmitAndBack(): void {
    this.onSubmit(true);
  }
  createClass(): void {
    this.formView();
  }
  editStudent(): void {
    console.log("editar Clase")
    this.fillEditForm();
    this.editView();
  }
  deleteClasses(): void {
    // Pregunta al usuario si realmente desea eliminar las carreras
    const confirmDelete = window.confirm('¿Estás seguro de que deseas eliminar estos elementos?');
    // Si el usuario confirma, procede con la eliminación
    if (confirmDelete) {
      this.deleteDataService();
    }
  }  

  formatDateDMY(dateString: string) {
    const date = new Date(dateString);
  
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    const year = date.getFullYear();
  
    return `${month}/${day}/${year}`;
  }
  getSelectedIds(): any[] {
    return this.selectedRows.map((row) => row.id);
  }
  getUniversityId(): number {
    if (localStorage.getItem('user') != null) {
      let JSONUser = JSON.parse(localStorage.getItem('user')??"{}");
      let user = JSONUser.user;
      return user.university_id;
    } else {
      return 0;
    }
  }  
  showAlert(message:any, type: any) {
    this.messageService.sendMessage({
      text: message,
      type: type,
      duration: 5000 
    });
  }  
}


