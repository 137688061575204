<app-header [titulo]="tituloDeLaVentana" [botones]="listaDeBotones"></app-header>
<app-spinner *ngIf="showSpinner"></app-spinner>
<!-- VISTA FORMULARIO -->
<div class="career-form" *ngIf="statusView=='form'">
    <form [formGroup]="form" (ngSubmit)="onSubmit(false)">
      <!-- Primera Fila -->
      <div class="form-row">
        <div class="form-group col">
          <label for="identifier">Identificador</label>
          <input type="text" id="identifier" formControlName="identifier" required>
        </div>
        <div class="form-group col">
          <label for="name">Nombre</label>
          <input type="text" id="name" formControlName="name" required>
        </div>
      </div>
        
      <!-- Botón de envío -->
      <div class="button-container" style="display:none;">
        <button type="submit">Guardar y Crear Nuevo</button>
      </div>
    </form>
  </div>
    <!-- VISTA LISTA DE ESTUDIANTES -->
    <app-grid [columns]="gridColumns" [data]="gridData" *ngIf="statusView=='grid'"></app-grid>