<div class="popup-overlay" (click)="closePopup()"></div>
<div class="popup-container">
  <div class="popup-header">
    <h3>Filtros</h3>
  </div>
  <div class="popup-body">
    <div class="student-form">
      <form [formGroup]="form" (ngSubmit)="onSubmit()">
        <div class="form-row">
          <div class="form-group col">
            <label for="group_id">Grupo</label>&nbsp;
            <select id="group_id" formControlName="group_id" required>
              <option [ngValue]="null" disabled>Selecciona un grupo</option>
              <option *ngFor="let group of groups" [ngValue]="group.id">{{ group.identifier }}</option>
            </select>
          </div>
      </div>
      <div class="form-row">
        <div class="form-group col">
          <label for="empresa_base">Empresa</label>&nbsp;
          <select id="empresa_base" formControlName="empresa_base" required>
            <option [ngValue]="null" disabled>seleccione una empresa</option>
            <option *ngFor="let empresa_base of empresas_base" [ngValue]="empresa_base.id">{{ empresa_base.identifier }}</option>
          </select>
        </div>
    </div>
    <div class="form-row">
      <!--<div class="form-group col">
        <label for="student">Estudiante</label>&nbsp;
        <select id="student" formControlName="student" required>
          <option [ngValue]="null" disabled>seleccione un estudiante</option>
          <option *ngFor="let student of students" [ngValue]="student.id">{{ student.name + " " + student.first_surname + " " + student.second_surname + " (" + student.student_number + ")"}}</option>
        </select>
      </div>-->
      <div class="form-group col">
        <label for="student">Estudiante</label>&nbsp;
        <input 
          style="padding: 10px 5px 10px 5px;"
          id="student"
          type="text"
          [value]="selectedStudentName"
          (input)="filterStudents($event)"
          (blur)="verifyStudent()"
          (focus)="showSuggestions = true"
          placeholder="Seleccione un estudiante"
          required
        />
        <ul *ngIf="showSuggestions && filteredStudents.length" class="suggestions-list">
          <li 
            *ngFor="let student of filteredStudents" 
            (click)="selectStudent(student)"
          >
            {{ student.name + " " + student.first_surname + " " + student.second_surname + " (" + student.student_number + ")" }}
          </li>
        </ul>
      </div>        
    </div>    
    <div class="button-container">
      <button (click)="closePopup()">Volver</button>
      <button type="submit">Buscar</button>
    </div>    
    </form>
    </div>
  </div>
  <div class="popup-footer">
  </div>
</div>
