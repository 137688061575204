import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth.service';
import { FormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { DataService } from '../data.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  username: string = '';
  password: string = '';
  message: string = '';
  showSpinner: boolean = false;

  constructor(private authService: AuthService, private router: Router, private dataService: DataService) {}

  ngOnInit(): void {
    
  }

  async login() {
    console.log("CHECK")
    this.showSpinner = true;
    let session = await this.authService.login({username: this.username, password: this.password}).toPromise();
    console.log("CHECK0")
    if (session.login_status) {
      console.log("CHECK1")
      localStorage.setItem('jwt', JSON.stringify(session.jwt));
      localStorage.setItem('user', JSON.stringify(session));
      console.log("CHECK2")
      this.showSpinner = false;
      this.router.navigate(['/']).then(() => {
        console.log("CHECK3")
        window.location.reload();
      });
    } else {
      localStorage.removeItem("jwt");
      localStorage.removeItem("user");
      this.showSpinner = false;
      this.message = 'Usuario o contraseña incorrectos. Intente de nuevo.';
    }
  }
}
