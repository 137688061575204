<header>
    <h1>{{ titulo }}</h1>
    <div>
      <!-- Iterar sobre la lista de botones y mostrarlos -->
      <button *ngFor="let button of botones" (click)="onButtonClick(button)" [ngClass]="button.class ? button.class : null">
        {{ button.texto }}
      </button>
    </div>
  </header>
  <div class="subtitle-header">{{ subtitulo }}</div>
  <app-filter-popup 
    *ngIf="showFilterPopup" 
    (onClose)="onHidePopup()" 
    (onSubmited)="onSubmit($event)"
    (displayPopUp)="onDisplayPopUp($event)">
  </app-filter-popup>